import React from 'react';
import './NavigationBar.scss';
import { Link } from 'react-scroll';
//assets
import hamburger from '../../assets/hamburger.png';
import twitter from '../../assets/twitter.svg';
import github from '../../assets/github.svg';
import linkedin from '../../assets/linkedin.svg';
import whitepaper from '../../assets/whitepaper.pdf';

const NavigationBar = () => {
  const readWhitepaper = () => {
    window.open(whitepaper);
  };
  return (
    <nav className='navbar navbar-expand-md navbar-dark bg-transparent'>
      <div className='container'>
        <h1> </h1>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <img src={hamburger} alt='hamburger' className='img-fluid' />
        </button>
        <div className='collapse navbar-collapse' id='navbarNav'>
          <ul className='navbar-nav'>
            <li className='nav-item me-md-5'>
              <Link
                to='documentation'
                spy={true}
                smooth={true}
                duration={500}
                className='nav-link'
              >
                Documentation
              </Link>
            </li>
            <li className='nav-item me-md-5'>
              <Link
                to='treasury'
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                className='nav-link'
              >
                Treasury
              </Link>
            </li>
            <li className='nav-item me-md-5'>
              <Link
                to='roadmap'
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                className='nav-link'
              >
                Roadmap
              </Link>
            </li>
            <li className='nav-item me-md-5'>
              <Link
                to='faq'
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                className='nav-link'
              >
                FAQ
              </Link>
            </li>
            <li className='nav-item me-md-5'>
              <span className='nav-link' onClick={readWhitepaper}>
                Concierge
              </span>
            </li>
            <li className='text-center mt-3 d-md-none'>
              <a href='https://twitter.com/' target='_blank' rel='noreferrer'>
                <img src={twitter} alt='discord' className='img-fluid mx-2' />
              </a>

              <a href='https://twitter.com/' target='_blank' rel='noreferrer'>
                <img src={linkedin} alt='linkedin' className='img-fluid mx-2' />
              </a>
              <a href='https://twitter.com/' target='_blank' rel='noreferrer'>
                <img src={github} alt='github' className='img-fluid mx-2' />
              </a>
            </li>
          </ul>
        </div>
        <div className='social-links d-none d-md-flex'>
          <a href='https://twitter.com/' target='_blank' rel='noreferrer'>
            <img src={twitter} alt='discord' className='img-fluid' />
          </a>

          <a href='https://twitter.com/' target='_blank' rel='noreferrer'>
            <img src={linkedin} alt='linkedin' className='img-fluid' />
          </a>
          <a href='https://twitter.com/' target='_blank' rel='noreferrer'>
            <img src={github} alt='github' className='img-fluid' />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
