import React from 'react';
import './Footer.scss';
import logo from '../../assets/logo.svg';
// import discord from '../../assets/discord.svg';
import twitter from '../../assets/twitter.svg';
import github from '../../assets/github.svg';
import linkedin from '../../assets/linkedin.svg';
import { Link } from 'react-scroll';
const Footer = () => {
  return (
    <footer className='container-fluid footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-4 mb-4 mb-md-0'>
            <h1>
              <img src={logo} alt='Whales DAO' className='img-fluid me-3' />{' '}
              <br className='d-md-none' />
              Whales DAO
            </h1>
            <div className='d-flex justify-content-center justify-content-md-start'>
              <button>Enter Dapp</button>
            </div>
          </div>
          <div className='col-12 col-lg-8'>
            <ul className='nav-list'>
              <li>
                <Link
                  to='documentation'
                  spy={true}
                  smooth={true}
                  duration={500}
                  className='nav-link'
                >
                  Documentation
                </Link>
              </li>
              <li>
                <Link
                  to='treasury'
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  className='nav-link'
                >
                  Treasury
                </Link>
              </li>
              <li>
                <Link
                  to='roadmap'
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  className='nav-link'
                >
                  Roadmap
                </Link>
              </li>
              <li>
                <Link
                  to='faq'
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  className='nav-link'
                >
                  FAQ
                </Link>
              </li>
            </ul>
            <ul className='social-links'>
              <li>
                <a href='https://twitter.com/' target='_blank' rel='noreferrer'>
                  <img src={twitter} alt='discord' className='img-fluid' />
                </a>
              </li>
              <li>
                <a href='https://twitter.com/' target='_blank' rel='noreferrer'>
                  <img src={linkedin} alt='linkedin' className='img-fluid' />
                </a>
              </li>
              <li>
                <a href='https://twitter.com/' target='_blank' rel='noreferrer'>
                  <img src={github} alt='github' className='img-fluid' />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p>© 2021 Whales DAO. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
