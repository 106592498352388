import React from 'react';
import './Working.scss';
const Working = () => {
  const data = [
    {
      count: '01',
      heading: 'LP FEES & BONDS',
      shadow: 'Growth',
      content:
        "As the Whales DAO Treasury grows, the inflow of liquidity steadily increases the Treasury's balance. By increasing the Treasury's balance, Whales DAO is able to back outstanding WHALES tokens and moderate staking APY.",
    },
    {
      count: '02',
      heading: 'WHALES’S  Treasury',
      shadow: 'TREASURY',
      content:
        'The Whales DAO Treasury generates Revenue from two sources. The first is from Liquidity Pool (LP) Fees, and the second is through sales of Bonds. This revenue helps Whales DAO lock in liquidity and control the WHALES Supply',
    },
    {
      count: '03',
      heading: 'WDAO TOKEN',
      shadow: 'Rewards',
      content:
        'By Staking WHALES tokens, holders are deepening liquidity for the DAO and establishing intrinsic value. In doing so, they will be able to automatically compound their yields over time.',
    },
  ];
  return (
    <section className='container-fluid working' id='treasury'>
      <div className='container'>
        <div className='title main'>
          <h2 className='heading'>How treasury workS?</h2>
          <h2 className='shadow'>Treasury</h2>
        </div>
        <div className='row g-5'>
          {data.map(({ count, heading, shadow, content }, index) => (
            <div className='col-12 col-md-4' key={index}>
              <div
                className={
                  count === '02' ? 'working-card mt-0 mt-md-5' : 'working-card'
                }
              >
                <span>{count}</span>
                <div className='title'>
                  <h2 className='heading'>{heading}</h2>
                  <h2 className='shadow'>{shadow}</h2>
                </div>
                <p>{content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='flex-center mt-5'>
        <button>Treasury</button>
      </div>
    </section>
  );
};

export default Working;
