import React from 'react';
import './Faq.scss';
import faq from '../../assets/faq-element.png';
import plus from '../../assets/plus.svg';
import minus from '../../assets/minus.svg';
import AnimateHeight from 'react-animate-height';
const Faq = () => {
  const [height, setHeight] = React.useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });
  const toggleHeight = (id) => {
    if (height[id] === 0) {
      setHeight({ ...height, [id]: 'auto' });
    } else {
      setHeight({ ...height, [id]: 0 });
    }
    // console.log(height);
  };
  const data = [
    {
      id: 1,
      question: 'Is WhalesDao a stablecoin?',
      answer: (
        <p>
          WhalesDao is not a stablecoin. It utilizes fractional treasury
          reserves to extract intrinsic value. Whales becoming an algorithmic
          reserve currency, will therefore provide free floating value.
        </p>
      ),
    },
    {
      id: 2,
      question: 'Is Whales worth buying after the initial launch/growth?',
      answer: (
        <p>
          When $WHALES is staked, your balance will increase with the
          circulating supply due to the staking protocol. Irrespective of which
          price point you buy you will always be earning staking income which
          reduces risks and catching the next price jumps.
        </p>
      ),
    },
    {
      id: 3,
      question: 'Does $WDAO have an audit?',
      answer: (
        <p>
          WHALES is going to be audited by Certik, one of the most trusted audit
          firms in the crypto market.
        </p>
      ),
    },
    {
      id: 4,
      question: 'What is APY?',
      answer: (
        <p>
          APY refers to Annual Percentage Yield. It utilizes a real rate of
          return through compounding interests. If you have $100 investment with
          an APY of 1000%, then 365 days later your investment will be $1,100.
          APY for WhalesDao is ever expanding as we grow together
        </p>
      ),
    },
    {
      id: 5,
      question: 'Are high APYs sustainable?',
      answer: (
        <p>
          APY relies on the sale of bonds in order to mint new $WHALES tokens.
          When sufficient bonds are sold the APY is sustainable. THE APY is
          predetermined and carefully calculated. THE APY is high from
          compounded interest and the autostaking feature allows for exponential
          growth.
        </p>
      ),
    },
  ];
  return (
    <section className='container-fluid faq'>
      <img src={faq} alt='faq' className='img-fluid element' />
      <div className='container'>
        <div className='title'>
          <h2 className='heading' data-aos='fade-up'>
            FAQs
          </h2>
        </div>
        <div className='faq-container'>
          {data.map(({ question, answer, id }, index) => (
            <div
              className='faq-card-wrapper'
              style={{ background: '#08B9FC' }}
              key={index}
              data-aos='fade-up'
            >
              <div className='faq-card'>
                <span>0{id}</span>
                <div className='title'>
                  <h4 className='question'>{question}</h4>
                  <img
                    src={height[id] === 0 ? minus : plus}
                    alt='arrow down'
                    className='img-fluid'
                    onClick={() => toggleHeight(id)}
                  />
                </div>
                <AnimateHeight duration={500} height={height[id]}>
                  {answer}
                </AnimateHeight>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
