import React from 'react';
import './RoadMap.scss';
import element from '../../assets/roadmap-element.png';
import { useScrollPercentage } from 'react-scroll-percentage';
const RoadMap = () => {
  const [ref, percentage] = useScrollPercentage({
    threshold: 0,
  });
  //scroll
  const data = [
    {
      count: 1,
      title: 'Q4 2021',
      list: (
        <ul>
          <li>WhalesDao Launch</li>
          <li>Staking and Bonding Live</li>
          <li>App Live</li>
          <li>Marketing Initiated</li>
          <li>USDC Bonding</li>
          <li>DAI Bonding</li>
        </ul>
      ),
    },
    {
      count: 2,
      title: 'Q1 2022',
      list: (
        <ul>
          <li>Apply to AVAX grants</li>
          <li>Build a WhalesDao UI for users</li>
          <li>Release Certik Audits</li>
          <li>Launch Dao Governance</li>
        </ul>
      ),
    },
    {
      count: 3,
      title: 'Q2 2022',
      list: (
        <ul>
          <li>Listing on centralized exchanges</li>
          <li>WhaleBank - Lending and borrowing protocol</li>
          <li>Additional bonds</li>
          <li>Introduce Dealflows (vetted profit sharing opportunities)</li>
          <li>WhalesDao NFTs</li>
        </ul>
      ),
    },
    {
      count: 4,
      title: 'Q3 2022',
      list: (
        <ul>
          <li>Crosschain expansion</li>
          <li>WhalesBridge</li>
          <li>Global Marketing initiatives</li>
          <li>Whales Metaverse Networking</li>
        </ul>
      ),
    },
  ];
  return (
    <section className='container-fluid roadmap'>
      <img src={element} alt='element' className='img-fluid element' />
      <div className='container'>
        <div className='title main'>
          <h2 className='heading'>Roadmap</h2>
          {/* <h2 className='shadow'>Road to Map</h2> */}
        </div>
        <div className='roadmap-container'>
          <div className='bar' ref={ref}>
            <div
              className='progress'
              style={{ width: `${percentage.toPrecision(2) * 100}%` }}
            ></div>
          </div>
          <div className='row'>
            {data.map(({ count, title, list }, index) => (
              <div className='col-12 col-md-6' key={index}>
                <div
                  data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'}
                  className={
                    count % 2 === 0
                      ? 'roadmap-card-container even'
                      : 'roadmap-card-container'
                  }
                >
                  <h2 className='shadow'>Phase {count}</h2>
                  <div className='roadmap-card'>
                    <span>{title}</span>
                    {list}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadMap;
