import React from 'react';
import './Distribution.scss';

const Distribution = () => {
  const data = [
    {
      title: 'Market Cap',
      value: '$$$',
    },
    {
      title: 'Price',
      value: '$$$',
    },
    {
      title: 'Treasury Balance',
      value: '$$$',
    },
    {
      title: 'Circulating supply',
      value: '$$$',
    },
  ];
  return (
    <div className='container distribution'>
      <div className='row'>
        {data.map(({ title, value }, index) => (
          <div className='col-12 col-md-6 col-lg-3 mt-5 mt-md-0' key={index}>
            <h3>{title}</h3>
            <h4>{value}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Distribution;
