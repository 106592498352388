import React from 'react';
import NavigationBar from '../navigation-bar/NavigationBar';
import './Hero.scss';
import logo from '../../assets/logo.svg';
import hero from '../../assets/hero.png';
import bg from '../../assets/hero-bg.svg';
import Distribution from '../distribution/Distribution';
const Hero = () => {
  return (
    <section className='container-fluid hero' id='hero'>
      <img src={bg} alt='background' className='img-fluid background' />
      <div className='container-wrapper'>
        <div className='container' id='main'>
          <NavigationBar />
          <div className='content-wrapper'>
            <div className='content'>
              <h1>
                <img
                  src={logo}
                  alt='Whales DAO'
                  className='img-fluid me-1 me-md-2'
                />{' '}
                Whales DAO
              </h1>
              <button>Launch Dapp</button>
            </div>
            <div className='slogan'>
              <div className='row'>
                <div className='col-12 col-md-7'>
                  <h1 className='animate__animated animate__fadeInLeft'>
                    Let’s Build Wealth.
                  </h1>
                  <h1 className='animate__animated animate__fadeInLeft animate__delay-1s'>
                    Secured Bonds.
                  </h1>
                  <h1 className='animate__animated animate__fadeInLeft animate__delay-2s'>
                    Backed Treasury.
                  </h1>
                  <h1 className='animate__animated animate__fadeInLeft animate__delay-3s'>
                    Exceptional Emission Testing.
                  </h1>
                </div>
                <div className='col-12 col-md-5 text-center'>
                  <img
                    src={hero}
                    alt='Whales DAO'
                    className='img-fluid hero-coin'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-none d-md-block'>
        <Distribution />
      </div>
    </section>
  );
};

export default Hero;
