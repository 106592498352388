import React, { useEffect } from 'react';
import Hero from './components/hero/Hero';
import './styles/main.scss';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Working from './components/working/Working';
import RoadMap from './components/roadmap/RoadMap';
import Faq from './components/faq/Faq';
import Footer from './components/footer/Footer';
import Distribution from './components/distribution/Distribution';

const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 0,
    });
  }, []);
  return (
    <>
      <Hero />
      <div className='d-block d-md-none'>
        <Distribution />
      </div>
      <Working />
      <RoadMap />
      <Faq />
      <Footer />
    </>
  );
};

export default App;
